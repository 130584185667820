.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    margin-top: 4rem;
  }
  
  .privacy-policy h1 {
    font-size: 2.5em;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    font-size: 1.8em;
    margin-top: 30px;
    color: #0056b3;
  }
  
  .privacy-policy p {
    margin-bottom: 15px;
  }
  
  .privacy-policy ul {
    margin: 15px 0;
    padding-left: 20px;
  }
  
  .privacy-policy ul li {
    margin-bottom: 10px;
  }
  
  .privacy-policy a {
    color: #0056b3;
    text-decoration: none;
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  